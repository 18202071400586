import React from 'react';
import { Link } from 'gatsby';
import mdToHtml from '../util/mdToHtml';

import mast from '../img/HomeMasthead.jpg';
import formBg from '../img/ContactBG.jpg';
import quote from '../img/Quotes.svg';
import Video from '../components/Video';

import ContactForm from '../components/ContactForm';
import BotttomLogoSection from '../components/BottomLogoSection';

import houseImg from '../img/homeHouse.png';

import b1 from '../img/Savings.png';
import b2 from '../img/CarbonFootprint.png';
import b3 from '../img/Maintenance.png';

import l1 from '../img/l1.svg';
import l2 from '../img/l2.svg';
import l3 from '../img/l3.svg';
import l4 from '../img/l4.svg';
import l5 from '../img/l5.svg';
import l6 from '../img/l6.svg';

import '../styles/HomeTemplate.scss';

const HomeTemplate = (props) => {
	const logos = [l1, l2, l3, l4, l5, l6];
	return (
		<main className="HomeTemplate" style={{ overflowX: 'hidden' }}>
			<section className="hero is-light is-bold">
				<div
					className="hero-body"
					style={{
						backgroundImage: `url(${mast})`,
					}}
				>
					<div className="container">
						<div className="columns is-mobile">
							<div className="column is-6 is-5-tablet" />
							<div className="column is-6 is-7-tablet">
								<h1>{props.content.masthead_heading}</h1>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="is-inverted is-skewed-bottom">
				<div className="section homeBlurb" style={{ paddingBottom: '12rem' }}>
					<div className="container">
						<h2>{props.content.lead_heading}</h2>
						<p>
							{props.content.lead_content}
						</p>
					</div>
				</div>
			</section>

			<section className="section">
				<div className="container">
					<div className="columns">
						<div className="column is-4 has-text-centered is-flex">
							<div style={{ margin: 'auto' }}>
								<h2>{props.content.home_heading}</h2>
								<ul className="is-special">
									{props.content.home_list.map((item, index) => (
										<li key={index}>{item.list_item}</li>
									))}
								</ul>
								<p style={{ margin: '2rem 0' }}>
									<strong>We can help.</strong>
								</p>
								<Link to="/residential">
									<button className="is-button">RESIDENTIAL SERVICES</button>
								</Link>
							</div>
						</div>
						<div className="column is-8">
							<div style={{ position: 'relative' }}>
								<div className="house-detail">
									<h3>{props.content.tip_heading}</h3>
									<p>{props.content.tip_content}</p>
								</div>
								<img
									src={houseImg}
									className="house"
									alt="House"
								/>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Video />

			<section className="afterVideo">
				<div
					className="section has-text-centered"
					style={{ paddingBottom: '5rem' }}
				>
					<h2>{props.content.benefits_heading}</h2>
					<div className="container">
						<div className="columns" style={{ margin: '3rem 0 4rem 0' }}>
							<div className="column">
								<div className="benefit">
									<div className="imgWrap">
										<img src={b1} alt="Airtight savings" />
									</div>
									<h3>{props.content.benefit_1_heading}</h3>
									<p>
										{props.content.benefit_1_content}
									</p>
								</div>
							</div>
							<div className="column">
								<div className="benefit">
									<div className="imgWrap">
										<img src={b2} alt="Airtight comfort" />
									</div>
									<h3>{props.content.benefit_2_heading}</h3>
									<p>
										{props.content.benefit_2_content}
									</p>
								</div>
							</div>
							<div className="column">
								<div className="benefit">
									<div className="imgWrap">
										<img src={b3} alt="Improved air quality" />
									</div>
									<h3>{props.content.benefit_3_heading}</h3>
									<p>
										{props.content.benefit_3_content}
									</p>
								</div>
							</div>
						</div>
					</div>
					<Link to="contact">
						<button className="is-button">CONTACT US TODAY</button>
					</Link>
				</div>
			</section>

			<section className="section is-muted featured-by">
				<div className="container has-text-centered">
					<h4>Brands We Trust:</h4>
					<div className="columns is-mobile is-multiline is-centered">
						{logos.map((logo, index) => (
							<div className="column is-6-mobile is-flex" key={index}>
								<img
									src={logo}
									alt={`logo-${index}`}
									style={
										index === 2
											? { margin: 'auto', width: '50%' }
											: { margin: 'auto', width: '66%' }
									}
								/>
							</div>
						))}
					</div>
				</div>
			</section>

			<div
				className="section has-text-centered testimony"
				style={{ paddingTop: '5rem', paddingBottom: '2rem' }}
			>
				<div className="container">
					<h2>{props.content.testimonials_heading}</h2>
					<div className="columns" style={{ margin: '2rem 0' }}>
						<div className="column has-text-left">
							<div className="has-text-centered">
								<img src={quote} alt="quotation" />
							</div>
							<div dangerouslySetInnerHTML={{ __html: mdToHtml(props.content.testimony_1_content) }}></div>
						</div>
						<div className="column has-text-left">
							<div className="has-text-centered">
								<img src={quote} alt="quotation" />
							</div>
							<div dangerouslySetInnerHTML={{ __html: mdToHtml(props.content.testimony_2_content) }}></div>
						</div>
					</div>
					<Link to="/faq">
						<button className="is-button">LEARN MORE</button>
					</Link>
				</div>
			</div>

			<section
				className="is-inverted is-skewed afterTestimony"
				style={{
					backgroundImage: `url(${formBg})`,
					backgroundPosition: 'center center',
					backgroundSize: 'cover',
				}}
			>
				<div
					className="section"
					style={{
						paddingTop: '16rem',
						paddingBottom: '12rem',
						marginBottom: '-6rem',
					}}
				>
					<div className="container">
						<div className="columns">
							<div className="column">
								<div className="contactInner">
									<h2>{props.content.contact_heading}</h2>
									<p>{props.content.contact_content}</p>
									<h3>
										<a className="phonelink" href="tel:17057952218">
											+1(705)&nbsp;795-2218
										</a>
									</h3>
								</div>
							</div>
							<div className="column has-text-centered">
								<div className="contactInner">
									<ContactForm />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<BotttomLogoSection />
		</main>
	);
};

export default HomeTemplate;
