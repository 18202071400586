import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import HomeTemplate from '../templates/HomeTemplate';
import Helmet from 'react-helmet';

const HomePage = ({ data }) => {
	const content = data.allMarkdownRemark.edges[0].node.frontmatter;
	return (
		<Layout>
			<Helmet>
				<title>Grid Zero Solar</title>
			</Helmet>
			<HomeTemplate content={content} />
		</Layout>
	);
};

export default HomePage;

export const query = graphql`
	{
		allMarkdownRemark(filter: {frontmatter: {slug: {eq: "home"}}}) {
			edges {
				node {
					frontmatter {
						masthead_heading
						lead_heading
						lead_content
						home_heading
						home_list {
							list_item
						}
						tip_heading
						tip_content
						benefits_heading
						benefit_1_heading
						benefit_1_content
						benefit_2_heading
						benefit_2_content
						benefit_3_heading
						benefit_3_content
						testimonials_heading
						testimony_1_content
						testimony_2_content
						contact_heading
						contact_content
					}
				}
			}
		}
	}
`;